<template>
  <footer>
    <nav>
      <ul>
        <li>
          <router-link to="/about">About</router-link>
        </li>
        <li>
          <router-link to="/contact">Contact</router-link>
        </li>
        <li>
          <router-link to="/faqs">FAQ</router-link>
        </li>
        <li>
          <router-link to="/privacy">Privacy Statement</router-link>
        </li>
      </ul>
    </nav>
    <hr />
    <p>IMPORTANT: Any data and information is provided 'as is' solely for informational purposes, and is not intended for
      trading or investment purposes or advice. Past performance is not indicative of future results. Before making
      financial decisions, we encourage you to seek appropriate legal, tax, and other professional advice. Copyright of
      information provided on this website is owned by Zentelligence Pty Ltd.</p>
    <p>© 2022 Zentelligence Pty Ltd. All Rights Reserved.</p>
    <p>{{ message }}</p>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      message: "",
    };
  },
  async mounted() {
    const apiResponse = await fetch("/api/message", {
      cache: "no-store", // no caching -- for demo purposes only
      method: 'GET',
      // headers: {
      //   'Authorization': `Bearer ${this.$store.getters.token}`
      // }
    });
    // const text = await apiResponse.text();
    // console.log(text);    
    // this.message = text;
    const json = await apiResponse.json();
    console.log(json);
    this.message = json.message;
  },
};
</script>

<style></style>