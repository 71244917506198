<template>
    <p v-if="isLoggedIn">Welcome, {{ getDisplayName }}</p>
    <a v-if="!isLoggedIn" href="/login">Login</a>
    <a v-if="isLoggedIn" href="/logout">Logout</a>
</template>

<script>
// if route was "logout", call logout() on page load
export default {
    methods: {
        login() {
            this.$store.dispatch('login');
        },
        logout() {
            this.$store.dispatch('logout');
            this.$router.replace('/')
        }
    },
    computed: {
        isLoggedIn() {
            return this.$store.getters.isAuthenticated;
        },
        getDisplayName() {
            return this.$store.getters.name;
        }
    },
};
</script>
