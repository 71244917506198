import { createStore } from 'vuex';

import authModule from './auth/index.js';
import adminModule from './admin/index.js';
import budgetModule from './budgetStore.js';
import clientModule from './client/index.js';

const store = createStore({
    modules: {
        auth: authModule,
        admin: adminModule,
        budget: budgetModule,
        client: clientModule,
    }
});

export default store;