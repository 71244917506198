import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
    namespaced: true,
    state() {
        return {
            id: null,
            city: null,
            state: null,
            country: null,
            email: null,
            name: null,
            givenName: null,
            familyName: null,
        }
    },
    mutations,
    actions,
    getters,
}