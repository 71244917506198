import { createApp } from 'vue';
import router from './routes.js';
import store from './store/index.js';

import App from './App.vue'
import './assets/global.css'

const app = createApp(App);
app.use(router);
app.use(store);
app.mount('#app');