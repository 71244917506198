import { createRouter, createWebHistory } from "vue-router";

// Home
import HomeMain from './components/public/HomeMain.vue'

// Public
import AboutMain from './components/public/AboutMain.vue';
import BlogMain from './components/public/BlogMain.vue';
import ContactMain from './components/public/ContactMain.vue';
import FaqMain from './components/public/FAQMain.vue';
import PrivacyMain from './components/public/PrivacyMain.vue';
import ServicesMain from './components/public/ServicesMain.vue';

// Account
// import AccountAuth from './components/account/AccountAuth.vue';
//import AuthCallback from './components/account/AuthCallback.vue';

// Client
import ClientMain from './components/client/ClientMain.vue';

// Admin
import AdminMain from './components/admin/AdminMain.vue';

// Error Handling
import NotFound from './components/NotFound.vue';


import store from './store/index.js';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            name: 'home', path: '/', meta: { needsAuth: false }, component: HomeMain
        },
        // account operations
        // {
        //     name: 'login', path: '/auth/login', meta: { needsAuth: false }, component: HomeMain
        // },
        // {
        //     name: 'logout', path: '/auth/logout', meta: { needsAuth: false }, component: HomeMain,
        //     beforeEnter(to, from, next) {
        //         store.dispatch('logout');
        //         next('/'); // redirect to home or login page after logging out
        //     }
        // },
        // {
        //     name: 'register', path: '/auth/register', meta: { needsAuth: false }, component: HomeMain
        // },
        // {
        //     name: 'auth', path: '/auth/callback', meta: { needsAuth: false }, component: AuthCallback
        // },
        // public
        {
            name: 'about', path: '/about', meta: { needsAuth: false }, component: AboutMain
        },
        {
            name: 'articles', path: '/articles', meta: { needsAuth: false }, component: BlogMain
        },
        {
            name: 'contact', path: '/contact', meta: { needsAuth: false }, component: ContactMain
        },
        {
            name: 'faqs', path: '/faqs', meta: { needsAuth: false }, component: FaqMain
        },
        {
            name: 'privacy', path: '/privacy', meta: { needsAuth: false }, component: PrivacyMain
        },
        {
            name: 'services', path: '/services', meta: { needsAuth: false }, component: ServicesMain
        },
        // private
        {
            name: 'admin', path: '/admin', meta: { needsAuth: true, needsAdmin: true }, component: AdminMain
        },
        {
            name: 'client', path: '/client', meta: { needsAuth: true }, component: ClientMain
        },
        { path: '/:notFound(.*)', component: NotFound },
    ],
});

router.beforeEach(function (toRoute, fromRoute, next) {
    console.log("Global::BeforeEach");
    if (toRoute.meta.needsAuth && !store.getters.isAuthenticated) {
        console.log("Global::BeforeEach: route requires authenticated user");
        next('/auth/login');
    } else if (toRoute.meta.needsAdmin && !store.getters.isAdmin) {
        console.log("Global::BeforeEach: route requires authenticated admin user");
        next('/');
    } else {
        next(true);
    }
});

// router.afterEach(function (toRoute, fromRoute) {
//     console.log("Global::AfterEach");
//     // send analytics info
// });


export default router;
