
// import myMSALObj from '@/services/authService.js';
// import { CacheLookupPolicy, InteractionRequiredAuthError } from "@azure/msal-browser";

export default {
    async tryAutoLogin(context) {
        var me = await fetch('/.auth/me');
        if (me.status == 200) {
            var data = await me.json();
            if (data.clientPrincipal != null) {
                var cp = data.clientPrincipal;

                if (cp.userRoles != null) {
                    var roles = cp.userRoles;
                    context.commit('setRoles', roles);
                }
                var username = cp.userDetails;
                var userId = cp.userId;
                console.log("UserId: ", userId);

                context.commit('setAuth', { username: username, userId: userId });

                var user = null;
                var uri = '/api/user/' + userId;
                var api_user = await fetch(uri, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (api_user.status == 200) {
                    user = await api_user.json();
                    context.commit('client/setUser', user);
                } else if (api_user.status == 404) {
                    user = {
                        id: userId,
                        userId: userId,
                        userName: username,
                        type: "user",
                        version: 1,
                    };

                    if (cp.claims != null) {
                        var claims = cp.claims;
                        for (var i = 0; i < claims.length; i++) {
                            var claim = claims[i];
                            if (claim.typ == "city") {
                                user.city = claim.val;
                            } else if (claim.typ == "state") {
                                user.state = claim.val;
                            } else if (claim.typ == "country") {
                                user.country = claim.val;
                            } else if (claim.typ == "emails") {
                                user.email = claim.val;
                            } else if (claim.typ == "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname") {
                                user.givenName = claim.val;
                            } else if (claim.typ == "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname") {
                                user.familyName = claim.val;
                            } else if (claim.typ == "name") {
                                user.name = claim.val;
                            }
                        }
                    }
                    console.log("POST /api/user", userId);
                    api_user = await fetch('/api/user', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(user)
                    });
                    if (api_user.status == 201) {
                        user = await api_user.json();
                        context.commit('client/setUser', user);
                    }
                } else {
                    console.log("Error: ", api_user.status);
                }

            }
        }
    }
}