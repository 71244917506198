export default {
  namespaced: true,
  state() {
    return {
      lastIndex: 10,
      incomes: [
        {type: "income", id: 5, name: "Salary", amount: 2000, freqCount: 2, freqUnit: "week"},
      ],
      needs: [
        { type: "need", id: 1, name: "Electricity", amount: 250.00, freqCount: 3, freqUnit: "month", fromDate: "2021-12-03", toDate: null },
        { type: "need", id: 2, name: "Water", amount: 150.00, freqCount: 12, freqUnit: "month", fromDate: "2022-03-23", toDate: null },
      ],
      wants: [],
      goals: [],
      debts: [],
      safety: [],
      ratios: []
    };
  },
  mutations: { 
    // Income

    // Needs
    addNeed(state, payload) {
      let newId = payload.id;
      if (!newId) {
        newId = state.lastIndex + 1;
        state.lastIndex += 1;
      } 
      console.log("Store[budget]::AddNeed("+newId+", "+payload.name+")");
      const need = {
        id: newId,
        type: payload.type,
        name: payload.name,
        amount: payload.amount,
        freqCount: payload.freqCount,
        freqUnit: payload.freqUnit,
        fromDate: payload.fromDate,
        toDate: payload.toDate
      };
      state.needs.push(need);
    },
    updateNeed(state, payload) {
      console.log("Store[budget]::UpdateNeed("+payload.id+")");
    },
    removeNeed(state, id) {
      console.log("Store[budget]::RemoveNeed("+id+")");
      state.needs = state.needs.filter(need => need.id !== id);
    },
    // Wants

    // Goals

    // Debts

    // Safety

    // Ratios
  },
  actions: {
    addItem(context, payload) {
      console.log("Store[budget]::AddItem("+payload.type+","+payload.name+")");
      switch(payload.type) {
        case "need":
          context.commit("addNeed", payload);
          break;
      }
      
      // if authenticated, persist to API
    },
    updateItem(context, payload) {
      console.log("Store[budget]::UpdateItem("+payload.type+","+payload.id+")");
      switch(payload.type) {
        case "need":
          //context.commit("updateNeed", payload);
          context.dispatch("removeItem", payload);
          context.dispatch("addItem", payload);
          break;
      }
      // if authenticated, persist to API
    },
    saveItems(context, payload) {
      switch (payload.type) {
        case "needs": context.commit("setNeeds", payload); break;
        case "wants": context.commit("setWants", payload); break;
        case "goals": context.commit("setGoals", payload); break;
        case "debts": context.commit("setDebts", payload); break;
        case "safety": context.commit("setSafety", payload); break;
      }
    },
    removeItem(context, payload) {
      console.log("Store[budget]::RemoveItem("+payload.type+", "+payload.id+")");
      switch(payload.type) {
        case "need":
          context.commit("removeNeed", payload.id);
          break;
      }
    },
  },
  getters: {
    getNextIndex(state) {
      return state.lastIndex + 1;
    },
    getIncomes(state) {
      return state.incomes;
    },
    getNeeds(state) {
      return state.needs;
    },
    getWants(state) {
      return state.wants;
    },
    getGoals(state) {
      return state.goals;
    },
    getDebts(state) {
      return state.debts;
    },
    getSafety(state) {
      return state.safety;
    },
    getRatios(state) {
      return state.ratios;
    },
  },
};