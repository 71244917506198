import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
    namespaced: false,
    state() {
        return {
            isAdmin: false,
            userId: null,
            roles: [],
        }
    },
    mutations,
    actions,
    getters,
}