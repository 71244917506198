// https://vuex.vuejs.org/en/getters.html

export default {
    isAuthenticated(state) {
        if (state.roles == null && state.roles.length === 0) {
            return false;
        }
        if (state.roles.includes('authenticated')) {
            return true;
        }
    },
    isAdmin(state) {
        if (state.roles == null && state.roles.length === 0) {
            return false;
        }
        if (state.roles.includes('Admin')) {
            return true;
        }
    },
    userId(state) {
        return state.userId;
    },
}