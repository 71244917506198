<template>
  <header>
    <nav>
      <ul>
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link to="/articles">Articles</router-link>
        </li>
        <li>
          <router-link to="/services">Services</router-link>
        </li>
        <li v-if="isLoggedIn">
          <router-link to="/client">Client</router-link>
        </li>
        <li v-if="isAdmin">
          <router-link to="/admin">Admin</router-link>
        </li>
        <li>
          <AccountAuth />
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
import AccountAuth from '../account/AccountAuth.vue';
export default {
  components: {
    AccountAuth
  },
  // methods: {
  //   login() {
  //     this.$store.dispatch("login");
  //   },
  //   logout() {
  //     this.$store.dispatch("logout");
  //     // navigate to "/"
  //   },
  // },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
}
</script>

<style></style>