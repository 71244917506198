export default {
    setUser(state, payload) {
        state.id = payload.id;
        state.country = payload.country;
        state.state = payload.state;
        state.city = payload.city;
        state.email = payload.email;
        state.name = payload.name;
        state.givenName = payload.givenName;
        state.familyName = payload.familyName;
    }
}