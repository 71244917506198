export default {
    id(state) {
        return state.id;
    },
    userName(state) {
        return state.userName;
    },
    city(state) {
        return state.city;
    },
    state(state) {
        return state.state;
    },
    country(state) {
        return state.country;
    },
    email(state) {
        return state.email;
    },
    name(state) {
        return state.name;
    },
    givenName(state) {
        return state.givenName;
    },
    familyName(state) {
        return state.familyName;
    },

}